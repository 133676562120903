body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F7F7F7;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    src: local('Roboto-Regular'), url("./fonts/Roboto-Regular.ttf") format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    src: local('Roboto-Bold'), url("./fonts/Roboto-Bold.ttf") format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: normal;
    src: local('Roboto-Italic'), url("./fonts/Roboto-Italic.ttf") format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: bold;
    src: local('Roboto-BoldItalic'), url("./fonts/Roboto-BoldItalic.ttf") format('truetype');
    font-display: swap;
}
